import React from "react";
import { css } from "@emotion/react";

import Text from "atoms/Text";
import HashLink from "atoms/HashLink";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import WidgetContainer from "atoms/WidgetContainer";
import ResponsiveDiv from "atoms/ResponsiveDiv";

import ShopdeckLogo from "molecules/ShopdeckLogo";

import LinkedIn from "icons/LinkedIn";
import Facebook from "icons/Facebook";
import YouTube from "icons/YouTube";
import Instagram from "icons/Instagram";
import LocationPin from "icons/LocationPin";
import Divider from "atoms/Divider";
import Link from "atoms/Link";
import scrollIntoView from "utils/scrollIntoView";


const styles = {
	footerWrapper: css`
		background-color: var(--color-dark-gray);
	`,
	addressBarWrapper: css`
		cursor: pointer;
	`,
	footerContentWrapperXL: css`
		padding-bottom: calc(18 * var(--spacing-xs));
	`,
	footerContentWrapper: css`
		padding-bottom: var(--spacing-2xl);
	`
}

const FindUsOnline = () => {
	return (
		<ResponsiveDiv>
			<Text color="gray-neutral" fontStyleGuide="body4" mb="md">Find Us Online</Text>
			<Flexbox direction="row" justify="start" align="center">
				<FlexboxItem colspan={"auto"} mr="md">
					<Link to="https://www.linkedin.com/company/shopdeck/" target="_blank" aria-label="Shopdeck LinkedIn Link">
						<LinkedIn />
					</Link>
				</FlexboxItem>
				<FlexboxItem colspan={"auto"} mr="md">
					<Link to="https://www.facebook.com/shopdeck.india" target="_blank" aria-label="Shopdeck Facebook Link">
						<Facebook />
					</Link>
				</FlexboxItem>
				<FlexboxItem colspan={"auto"} mr="md">
					<Link to="https://www.youtube.com/@ShopDeck__India" target="_blank" aria-label="Shopdeck YouTube Link">
						<YouTube />
					</Link>
				</FlexboxItem>
				<FlexboxItem colspan={"auto"} mr="md">
					<Link to="https://www.instagram.com/shopdeck__india/" target="_blank" aria-label="Shopdeck Instagram Link">
						<Instagram />
					</Link>
				</FlexboxItem>
			</Flexbox>
		</ResponsiveDiv>
	)
}

const AddressBar = () => {
	return (
		<Link to="https://www.google.com/maps/place/IndiQube+Alpha/@12.9394162,77.6930455,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae13b26920ebe3:0xfc72f83616e55cad!8m2!3d12.939411!4d77.6956204!16s%2Fg%2F11b7lphskt?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D" target="_blank">
			<Flexbox direction="row" justify="space-between" align="flex-start">
				<FlexboxItem colspan={"auto"} mr="sm">
					<LocationPin />
				</FlexboxItem>
				<FlexboxItem colspan={24} colspanXL={22}>
					<Text color="gray-neutral" fontStyleGuide="body5" mb="xs">
						1st Floor, IndiQube Alpha,
					</Text>
					<Text color="gray-neutral" fontStyleGuide="body5" mb="xs">
						Marathahalli Panathur Junction, Outer Ring Rd,
					</Text>
					<Text color="gray-neutral" fontStyleGuide="body5" mb="xs">
						Kadubeesanahalli, Bengaluru, Karnataka 560103
					</Text>
				</FlexboxItem>
			</Flexbox>
		</Link>
	)
}

export default () => {
	return (
		<footer
			css={styles.footerWrapper}
		>
			<WidgetContainer>
				<ResponsiveDiv css={styles.footerContentWrapper} xl={styles.footerContentWrapperXL}>
					<Flexbox direction="row" justify="space-between" align="flex-start" gap={40}>
						<FlexboxItem colspan={24} colspanXL={8}>
							<ShopdeckLogo />
							<Text color="gray-neutral" fontStyleGuide="body5" mt="md" mb="lg">
								Businesses from all over India use ShopDeck to manage their business and sell online.
							</Text>
							<ResponsiveDiv
								xs={css`
									display: none;
								`}
								xl={css`
									display: block;
								`}
							>
								<FindUsOnline />
								<ResponsiveDiv
									mt="lg"
								>
									<AddressBar />
								</ResponsiveDiv>
							</ResponsiveDiv>
						</FlexboxItem>
						<FlexboxItem colspan={24} colspanXL={9}>
							<Flexbox direction="row" justify="space-between" align="flex-start">
								<FlexboxItem colspan={24} colspanXL={8} mb="2xl">
									<Text color="gray-neutral" fontStyleGuide="body3" mb="xxl">
										Product
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<HashLink to="/#our-offerings">Offerings</HashLink>
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<HashLink to="/#testimonials">Testimonials</HashLink>
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<HashLink to="/#pricing">
											Pricing
										</HashLink>
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4">
										<Link to="https://pro.shopdeck.com/login" target="_blank">Login</Link>
									</Text>
								</FlexboxItem>
								<FlexboxItem colspan={24} colspanXL={8} mb="2xl">
									<Text color="gray-neutral" fontStyleGuide="body3" mb="xxl">
										Company
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<HashLink to="/#run-your-d2c-brand" onClick={() => window.scrollTo(0,0)}>Home</HashLink>
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<HashLink to="/#founders">About Us</HashLink>
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<Link to="/careers" target="_blank">Careers</Link>
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<HashLink to="/#contact-us">Contact Us</HashLink>
									</Text>
								</FlexboxItem>
								<FlexboxItem colspan={24} colspanXL={8} mb="2xl">
									<Text color="gray-neutral" fontStyleGuide="body3" mb="xxl">
										Legal
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4" mb="md">
										<Link to="/privacy-policy">Privacy Policy</Link>
									</Text>
									<Text as="div" color="white" fontStyleGuide="body4">
										<Link to="/terms">Terms and Conditions</Link>
									</Text>
								</FlexboxItem>
							</Flexbox>
						</FlexboxItem>
					</Flexbox>
					<ResponsiveDiv
						xl={css`
						display: none;
					`}
					>
						<AddressBar />
						<ResponsiveDiv mt="lg">
							<FindUsOnline />
						</ResponsiveDiv>
					</ResponsiveDiv>
				</ResponsiveDiv>
				<Divider color="dark-gray-neutral" spacing="xs" />
				<Text color="gray-neutral" fontStyleGuide="body5" mt="xxxl">
					@2024 ShopDeck. All rights reserved
				</Text>
			</WidgetContainer>
		</footer>
	)
}
