import React from "react";
import { css } from "@emotion/react";

import { InputProps } from "./types";
import Text from "atoms/Text";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import Alert from "icons/Alert";
import Flexbox from "atoms/Flexbox";

const styles = {
	baseStyle: css`
		background-color: var(--color-dark-gray-neutral);
		border-radius: var(--spacing-sm);
		border: 1px solid var(--color-dark-gray-neutral);
		padding: var(--spacing-md);
		color: var(--color-white);

		// fonts
		font-family: var(--font-family-body5);
		font-size: var(--font-size-body5);
		font-weight: var(--font-weight-body5);
		line-height: var(--font-lineheight-body5);

		&::placeholder {
			color: var(--color-gray-neutral);
			// fonts
			font-family: var(--font-family-body5);
			font-size: var(--font-size-body5);
			font-weight: var(--font-weight-body5);
			line-height: var(--font-lineheight-body5);
		}

		&:focus-visible {
			outline: none;
		}

		&:-webkit-autofill {
			-webkit-background-clip: text;
			-webkit-text-fill-color: var(--color-white);
			box-shadow: inset 0 0 0 100px var(--color-dark-gray-neutral);
			caret-color: var(--color-white);
		}
	`,
	erroredStyle: css`
		border: 1px solid var(--color-red500);
	`,
	fullWidthStyle: css`
		width: 100%;
	`,
	validationMessageWrapper: css`
		transition: all .2s linear;
		transform: translateY(-100%);
		opacity: 0;
	`,
	validationMessageWrapperOpen: css`
		transform: translateY(0);
		opacity: 1;
	`,
	inputWrapper: css`
		width: 100%;
		position: relative;
		border-radius: var(--spacing-sm);
	`,
	inputWrapperError: css`
		border: 1px solid var(--color-red500);
	`,
	leftAddonStyle: css`
		color: var(--color-white);
		padding: var(--spacing-md);
		position: absolute;
		border-right: 1px solid var(--color-gray);
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	`,
	inputWithAddonStyle: css`
		padding-left: calc(40px + var(--spacing-md));
	`
}

export default (props: InputProps): JSX.Element => {
	const { validationType, leftAddon, validationMessage, title, required, mt, mb, ml, mr, fullWidth, ...rest } = props;

	return (
		<ResponsiveDiv
			mt={mt}
			mb={mb}
			ml={ml}
			mr={mr}
		>
			<Text
				as="div"
				fontStyleGuide="body5"
				color="white"
				mb="xs"
			>
				{title}
				{required && <sup>&nbsp;*</sup>}
			</Text>
			<label htmlFor={props.id}>
				<div
					css={css`
					${styles.inputWrapper}
					${validationType === 'error' && styles.inputWrapperError}
				  `}
				>
					{leftAddon && (
						<div css={styles.leftAddonStyle}>
							{leftAddon}
						</div>
					)}
					<input
						css={css`
							${styles.baseStyle};
							${validationType === 'error' && styles.erroredStyle};
							${fullWidth && styles.fullWidthStyle};
							${leftAddon && styles.inputWithAddonStyle};
						`}
						{...rest}
					/>
				</div>
			</label>
			<ResponsiveDiv
				mt="xs"
				css={css`
					${styles.validationMessageWrapper};
					${(validationType !== 'none' && validationType) ? styles.validationMessageWrapperOpen : ''};
				`}
			>
				{(validationType === 'none' || !validationType) && <Text fontStyleGuide="body5" color="black">&nbsp;</Text>}
				{validationType === 'error' && (
					<Flexbox direction="row" justify="flex-start" align="center" wrap="nowrap">
						<Alert />&nbsp;
						<Text
							wordWrap="normal"
							textWrap="nowrap"
							fontStyleGuide="body5"
							color="red500"
						>
							{validationMessage}
						</Text>
					</Flexbox>
				)}
			</ResponsiveDiv>
		</ResponsiveDiv>
	)
}
