import React from "react";
import { css } from "@emotion/react";

import Text from "atoms/Text";
import Image from "atoms/Image";
import WidgetContainer from "atoms/WidgetContainer";
import Flexbox from "atoms/Flexbox";
import Button from "atoms/Button";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import Animated from "molecules/Animated";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import { useInView } from "react-intersection-observer";

const heroImageUrl = `https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-1200,f-webp,fo-auto/shopdeck-home/d2c-brand-hero-1200.png`;

const styles = {
	heroImageContainer: css`
	  width: 100%;
		transform-style: preserve-3d;
		will-change: transform;
		transition: transform .5s ease-in-out;
		transform: scale3d(0.7,0.7,1);
	`,
	heroImageStyle: css`
		max-width: 100%;
		height: auto;
	`,
	bookDemoBtnInitialStyle: css`
		opacity: 0;
		transform: translateY(50px); /* Start further down for a smoother effect */
		transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
		position: fixed;
		bottom: 23px;
		right: 13px;
		z-index: 1000;
  `,
  nonViewModeBookDemoStyle: css`
	  opacity: 1 !important;
      transform: translateY(0);
  `,
   fixedBookDemoWrapperInitialStyle: css`
     display: none;  
   `
}


export default () => {
	const { ref: containerRef, inView, entry } = useInView({
		triggerOnce: false,
		threshold: 1
		//rootMargin: `${-50-(props.index*5)}% 0% ${-50-(props.index*5)}% 0%`,
		//rootMargin: `-50% 0% -50% 0%`
	}); 


 	 const { ref: bookDemoBtnContainerRef, inView: bookDemoBtnInView, entry: bookDemoBtnEntry} = useInView({
			triggerOnce: false,
			threshold: 1,
		    rootMargin: `-50px 0% 0% 0%`
		}); 

	

	return (
    <ResponsiveDiv>
	   <WidgetContainer id="run-your-d2c-brand" >
			<ResponsiveDiv ref={containerRef}>
				<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
					<Text as="h1" mb="xxl" fontStyleGuideXL="manrope-heading1" fontStyleGuide="manrope-heading4" color="white" align="center" wordWrap="break-word">
						Run Your D2C <br></br> Business&nbsp;
						<Text fontStyleGuideXL="manrope-heading1" fontStyleGuide="manrope-heading4" as="span" primaryGradient={true}>“Profitably”</Text>
					</Text>
					{/* <Text color="white" align="center" fontStyleGuide="body3">{`Support of useInView: ${inView}`}</Text> */}
				</Animated>
			</ResponsiveDiv>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.2}>
				<Text as="p" mb="xxl" fontStyleGuide="body3" align="center" textWrap="wrap" color="gray-neutral">
					Your all-in-one stack to run profitable, <br></br>streamlined storefronts with ease
				</Text>
			</Animated>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.4}>
				<Flexbox direction="row" justify="center" align="center">
          <div ref={bookDemoBtnContainerRef}>
            <FlexboxItem colspan={"auto"} mb="xxl" >
              <Button type="primary" to="/book-demo-form" >Book a Free Demo</Button>
            </FlexboxItem>
          </div>
				</Flexbox>
			</Animated>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.6}>
				<div
					css={css`
						${styles.heroImageContainer};
						${!inView && `transform: scale3d(1,1,1);`}
					`}>
					<Image
						css={css`
							${styles.heroImageStyle};
						`}
						src={heroImageUrl}
            width={1200}
            height={756}
						alt="shopdeck-dashboard-hero-image"
					/>
				</div>
			</Animated>
	   	</WidgetContainer>
      <ResponsiveDiv xl={styles.fixedBookDemoWrapperInitialStyle} css={css`
          ${styles.bookDemoBtnInitialStyle};
          ${!bookDemoBtnInView && bookDemoBtnEntry && styles.nonViewModeBookDemoStyle};
        `}>
         <Button type="primary" to="/book-demo-form">Book a Free Demo</Button>
      </ResponsiveDiv>
    </ResponsiveDiv>
	
	)
}
